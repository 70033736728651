@tailwind base;
@tailwind components;
@tailwind utilities;

  
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap') format('woff2');
}

*{
  font-family: 'Open Sans', sans-serif;
  scroll-behavior: smooth;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v107/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDCvHOej.woff2) format('woff2');
}
 
.material-symbols-outlined {
font-family: 'Material Symbols Outlined';
font-weight: normal;
font-style: normal;
font-size: 24px;
line-height: 1;
letter-spacing: normal;
text-transform: none;
display: inline-block;
white-space: nowrap;
word-wrap: normal;
direction: ltr;
-moz-font-feature-settings: 'liga';
-moz-osx-font-smoothing: grayscale;
}
  

  .banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust opacity (0.5 for 50% opacity) and color as needed */
    z-index: 1;
  }

  .nav-bar{
    width: 100%;
    position: fixed;
    z-index: 50;
  }

  .nav-bar.active{
    background: #202124;
  }

.swiper-button-prev:after, .swiper-button-next:after{
   color: white !important;
}

.swiper-button-prev,
.swiper-button-next{
  margin: -5px;
}

@media only screen and (max-width: 768px) {
  .swiper-button-prev:after, .swiper-button-next:after{
    font-size: 20px !important;
  } 
}

@media only screen and (max-width: 426px){
.swiper-button-prev:after,
  .swiper-button-next:after {
    display: none;
  }
}

 /* (left) 
.swiper-button-prev {
  @apply bg-transparent text-[#072FA5]  rounded-full p-7 m-2; 
  @apply text-sm lg:text-base; 
  @apply transition transform hover:scale-110;
  @apply lg:ml-8;
}

 (right) */
/* .swiper-button-next {
  @apply bg-transparent text-[#072FA5]  rounded-full p-7 m-2; 
  @apply text-sm lg:text-base; 
  @apply transition transform hover:scale-110;
  @apply  lg:mr-8;
} */

.selectable {
  -webkit-user-select: text;
  /* Safari */
  -moz-user-select: text;
  /* Firefox */
  -ms-user-select: text;
  /* IE 10+/Edge */
  user-select: text;
  /* Standard syntax */
}

.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 90%;
  border: none;
  object-fit: cover;
}

P{
  letter-spacing: 1px !important;
  line-height: 35px !important;
  word-spacing: -1.5px !important;
}

h1{
  /* letter-spacing: 1px !important; */
  word-spacing: -1.5px !important;
}

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: #ffffff !important;
  opacity: 0.9 !important;
}

.swiper-pagination-bullet-active{
  background: #072FA5 !important;
}

